import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";

import Meta from "../components/meta";
import Layout from "../components/layout";
import Submv from "../components/submv";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

// markup
const OnlinePage = ({ location }) => {
  return (
    <Layout>
      {/* サイトタイトル */}
      <Meta title="オンライン相談" />

      {/* <Submv title="オンライン相談" /> */}

      <div style={{ backgroundColor: "#f1f4f1", zIndex: 10, position: "relative" }}>
        <div className="container">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <a className="breadcrumb__link" href="/">
                  ホーム
                </a>
                <span className="breadcrumb__separator" aria-hidden="true">
                  {" "}
                  &gt;{" "}
                </span>
              </li>
              <li className="breadcrumb__item">
                <a aria-current="page" className="breadcrumb__link" href="/online/">
                  オンライン相談
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <section className="sec position-relative bg-illust pt-0" style={{ backgroundColor: "#f1f4f1" }}>
        {/* <div className="illust illust--1">
          <StaticImage alt="illust" src="../images/illust_01.png" />
        </div>
        <div className="illust illust--2">
          <StaticImage alt="illust" src="../images/illust_02.png" />
        </div> */}
        <Container>
          <h2
            className="text-center font-weight-bold mb-4"
            style={{
              color: "#8ac242",
              fontSize: "220%",
            }}
          >
            太陽光発電に関することを
            <br />
            ビデオ通話でオンライン相談
          </h2>
          <p className="text-center">
            太陽光発電に関する様々な不安や疑問をオンラインのビデオ通話で一つ一つ丁寧にお答えします。
            <br />
            どうぞお気軽にご相談ください。
          </p>
          <div className="my-5 text-center">
            <StaticImage alt="ビデオ通話でオンライン相談" src="../images/img-online@2x.png" style={{ maxWidth: "660px" }} />
          </div>
          <div className="bg-white online-box">
            <StaticImage className="online-ball" alt="ビデオ通話でオンライン相談" src="../images/online-ball@2x.png" height={70} />
            <p className="m-0 text-center">
              平安コーポレーションの「オンライン相談」は、スマートフォンやパソコンで、
              <br />
              スタッフと顔を合わせながら相談ができるサービスです。
              <br />
              資料や画面を共有しながらご案内いたしますので、ご自宅にいながら対面と同様のお打合せが可能です。
              <br />
              ご都合のよい時間に、納得がいくまでじっくりご相談ください。
            </p>
          </div>
        </Container>
      </section>

      <section className="sec">
        <Container>
          <h2 className="font--xlarge font-weight-bold text-center mb-md-5 mb-4">オンライン相談のメリット</h2>
          <Row>
            <Col md={4}>
              <StaticImage alt="illust" src="../images/online-pict-1@2x.png" />
              <p className="text-center my-3 font-weight-bold">顔を見ながら相談できる</p>
              <p className="small mb-0">複数人でお互いの顔を見ながらコミュニケーションがとれます。</p>
            </Col>
            <Col md={4} className="mt-md-0 mt-4">
              <StaticImage alt="illust" src="../images/online-pict-2@2x.png" />
              <p className="text-center my-3 font-weight-bold">資料や動画なども共有できる</p>
              <p className="small mb-0">画面や資料を共有して本格的な商談も可能です。</p>
            </Col>
            <Col md={4} className="mt-md-0 mt-4">
              <StaticImage alt="illust" src="../images/online-pict-3@2x.png" />
              <p className="text-center my-3 font-weight-bold">場所や機器を問わない</p>
              <p className="small mb-0">パソコン・タブレットなどを使って、場所を問わず参加できます。</p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec" style={{ backgroundColor: "#f6fafa" }}>
        <Container>
          <h2 className="font--xlarge font-weight-bold text-center mb-md-5 mb-4">対応スタッフ</h2>
          <Row className="flex-wrap">
            <Col xs={6} md={4} className="mb-4">
              <StaticImage alt="illust" src="../images/staff-1@2x.png" />
              <p className="text-center mt-3 mb-0 font-weight-bold">社長</p>
            </Col>
            <Col xs={6} md={4} className="mb-4">
              <StaticImage alt="illust" src="../images/staff-2@2x.png" />
              <p className="text-center mt-3 mb-0 font-weight-bold">今井（太陽）</p>
            </Col>
            <Col xs={6} md={4} className="mb-4">
              <StaticImage alt="illust" src="../images/staff-3@2x.png" />
              <p className="text-center mt-3 mb-0 font-weight-bold">山本（住宅）</p>
            </Col>
            <Col xs={6} md={4} className="mb-4">
              <StaticImage alt="illust" src="../images/staff-4@2x.png" />
              <p className="text-center mt-3 mb-0 font-weight-bold">杉本（住宅）</p>
            </Col>
            <Col xs={6} md={4} className="mb-4">
              <StaticImage alt="illust" src="../images/staff-5@2x.png" />
              <p className="text-center mt-3 mb-0 font-weight-bold">中尾（太陽）</p>
            </Col>
            <Col xs={6} md={4} className="mb-4">
              <StaticImage alt="illust" src="../images/staff-6@2x.png" />
              <p className="text-center mt-3 mb-0 font-weight-bold">畑中（住宅）</p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec zoom">
        <Container className="position-relative">
          <div className="zoom--wrap">
            <h2 className="font--xlarge font-weight-bold text-center mb-md-5 mb-4">オンライン相談には「Zoom」を使用します</h2>
            <p
              className="font-weight-bold"
              style={{
                color: "#0284fd",
                fontSize: "220%",
              }}
            >
              無料で使える！だから安心！
            </p>
            <p className="font-weight-bold">
              オンライン相談では無料ビデオ会議システムの
              <br />
              「Zoom」を使用します。
              <br />
              対面でお話しているようなリアル感です。
              <br />
              資料など画面で共有しながら分かりやすくご説明いたします。
            </p>
            <a
              className="btn btn-block btn-outline-primary bg-white font-weight-bold my-3"
              href="https://zoom.us/download"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#0284fd",
                borderColor: "#0284fd",
              }}
            >
              事前にZoomアプリをダウンロードする
            </a>
            <p className="font-weight-bold">無料ビデオ会議システム「Zoom 」はWebブラウザ上での使用が可能ですが、アプリをダウンロードいただくと、より快適にご利用いただけます。</p>
          </div>
        </Container>
      </section>

      <section className="sec">
        <Container>
          <h2 className="font--xlarge font-weight-bold text-center mb-md-5 mb-4">
            カンタン<span style={{ fontSize: "150%" }}>4</span>STEP
          </h2>
          <Row className="pb-5">
            <Col md={3} xs={6}>
              <StaticImage alt="step" src="../images/step-1@2x.png" />
              <p className="mb-0 mt-3">お問い合わせフォームよりオンライン相談を希望の旨、送信してください。</p>
              <a
                className="btn btn-block btn-warning text-white font-weight-bold mt-3"
                href="http://www.heian-corp.jp/contact/solar/index.php"
                target="_blank"
                rel="noreferrer"
                style={{ minWidth: "1px" }}
              >
                お問い合わせ
              </a>
            </Col>
            <Col md={3} xs={6}>
              <StaticImage alt="step" src="../images/step-2@2x.png" />
              <p className="mb-0 mt-3">お問い合わせ内容を確認後、担当者より日時のご提案をさせて頂きます。</p>
            </Col>
            <Col md={3} xs={6} className="mt-md-0 mt-4">
              <StaticImage alt="step" src="../images/step-3@2x.png" />
              <p className="mb-0 mt-3">メールにて招待用のURLをお送りします。</p>
            </Col>
            <Col md={3} xs={6} className="mt-md-0 mt-4">
              <StaticImage alt="step" src="../images/step-4@2x.png" />
              <p className="mb-0 mt-3">オンライン商談当日の時間になりましたら、お送りしたURLにアクセスしてオンライン相談スタート！</p>
            </Col>
          </Row>
          <div className="bg-white online-box mt-5">
            <StaticImage className="online-ball" alt="ビデオ通話でオンライン相談" src="../images/online-ball-2@2x.png" height={70} />
            <div className="text-center mt-5">
              <StaticImage alt="ビデオ通話でオンライン相談" src="../images/pc-tablet@2x.png" width={500} />
            </div>
            <p className="m-0 text-center small">※インターネットに接続できるPCまたはタブレットをご用意ください。スマホは画面が小さいので推奨しておりません。</p>
            <a
              className="btn w-100 btn-outline-primary bg-white font-weight-bold mt-4"
              href="https://support.zoom.us/hc/ja"
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#0284fd",
                borderColor: "#0284fd",
                maxWidth: "600px",
              }}
            >
              <FontAwesomeIcon className="fai" icon={faQuestionCircle} /> パソコンからZoomに参加する方法はこちら
            </a>
          </div>
          <div className="mt-5 text-center">
            <a href="http://www.heian-corp.jp/contact/solar/index.php" className="btn btn-secondary font-weight-bold" target="_blank" rel="noreferrer">
              オンライン相談のお申込みはこちら
            </a>
          </div>
        </Container>
      </section>

      <section className="sec pt-0" id="faq">
        <Container>
          <h2 className="ttl--h2">よくある質問</h2>
          <dl className="faq-list">
            <dt>相談する時間に制限はありますか？</dt>
            <dd>相談の内容にもよりますが、30分〜1時間程度が平均相談時間となります。</dd>
          </dl>
          <dl className="faq-list">
            <dt>お問い合わせフォームからの予約は何日前までに必要ですか？</dt>
            <dd>2日前までにお願いします。2日前を過ぎてのご予約はお電話にてご相談ください。</dd>
          </dl>
          <dl className="faq-list">
            <dt>今すぐオンライン相談をお願いしたいのですが、可能でしょうか？</dt>
            <dd>まずはお電話ください。担当者のスケジュールを確認してご対応可能であればすぐに開始も可能です。</dd>
          </dl>
          <dl className="faq-list">
            <dt>zoomアプリは必ずダウンロードする必要がありますか。</dt>
            <dd>
              タブレットをご利用の場合はダウンロードする必要があります。
              <br />
              パソコンの場合はダウンロードしない方法もございますので、お電話にてご相談ください。
            </dd>
          </dl>
        </Container>
      </section>
    </Layout>
  );
};

export default OnlinePage;
